import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/en/analytics-consulting",
  "Google Analytics Setup": "/en/google-analytics-setup"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-analytics-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-analytics-einrichten"
  },
  {
    hreflang: "x-default",
    href: "/en/google-analytics-setup"
  }
];

const AnalyticsSetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Set up Google Analytics from scratch! [${currentYear} Guide]`}
        description="Guide on how to configure a modern Google Analytics setup. Goal setup, event tracking and best practices. Tutorial from certified Google Analytics consultant."
        lang="en"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
        canonical="/en/google-analytics-setup"
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="man working on Google Analytics setup with laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics Setup Guide</H>
        <p>
          One of the first tasks for a new website is to set up Google Analytics. After all the time and
          effort you invested in building a website, you want to measure and analyse the user behavior of course. You
          may have questions like:
        </p>

        <ul>
          <li>How is my content performing?</li>
          <li>which product category is the most popular?</li>
          <li>How many conversions did my Facebook campaign trigger?</li>
          <li>Do visitors actually watch the video I produced?</li>
          <li>Are there any pages that perform particularly bad?</li>
          <li>Do visitors share anything on social media?</li>
          <li>Do people drop out during the checkout process?</li>
        </ul>

        <p>
          All assumptions about user behaviour as well as campaign performance must be validated. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% of all websites on the internet use Google Analytics</a> to do that. In fact, it has a <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">market share of 84% in the traffic analysis tool market</a>, so you don't have to worry that you are choosing the wrong analytics tool.
        </p>
        <p>
          A web analytics tool enables you to make decisions based on data instead of guessing. Such decisions can be for example about what type of content to invest in or how much budget you should spend on ad campaigns.</p>
        <p>Therefore it is of essential importance to set up Google Analytics professionally, so you track the right KPIs for your analysis. With such a setup in place, you'll have plenty of metrics available to
          dive into.
        </p>
        <H as="h2">Types of Google Analytics setups</H>
        <p>
          The type of website is a key factor for a Google Analytics setup:
          <br />
          Are you running an online shop or sell products? <br />
          Then {/* <Link to="/en/ecommerce-analytics"> */}
          e-commerce analytics{/* </Link> */} is the way to go. It is more product- and transaction-oriented and will
          give you helpful insights you don't want to miss.
        </p>
        <p>
          If you run a content-focused site about your brand, then a typical Google Analytics tracking setup is the
          right fit. <br />
          You can track user interactions with <Link to="/en/event-tracking">event tracking</Link> and oversee
          important website KPIs with{" "}
          <Link to="/en/google-analytics-setup#how-to-set-up-goals-in-google-analytics">Google Analytics goals</Link>.
        </p>

        <p>
          E-commerce analytics setups require more data about products and transactions. That's why the configuration
          is a bit more complex, involving custom JavaScript to build a so-called{" "}
          <Link to="/en/data-layer">data layer</Link>
          . <br />A data layer is essentially a store of data, that holds information about each page. Google
          Analytics will then recognize and use it, when available.
        </p>
        <p>
          Don’t worry for now if you realize, you need to set up Google Analytics for an e-commerce site. You can
          activate e-commerce functionality later on and also enhance the data layer, once you are prepared to do so.{" "}
          <br />
          Until then, e-commerce reports will stay blank, but other reports will work just fine.{" "}
        </p>

        <H as="h2">Where to get the Google Analytics Tracking Code?</H>
        <p>
          Visit{" "}
          <a href="https://analytics.google.com" target="_blank" rel="noopener">
            analytics.google.com
          </a>{" "}
          and click the <strong>Signup</strong> button to create a Google Analytics account. You'll get the Google
          Analytics tracking code, once you follow below steps.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
          alt="Google Analytics signup screenshot"
          className="article-img"
        />
        <ul>
          <li>
            After you click the <strong>signup</strong> button, click further and choose a property name and URL
            followed by your industry and time zone.{" "}
          </li>
          <li>
            I recommend <strong>deselecting all data sharing options</strong>, if you are not fully on top of your
            privacy policy. But that means Google will <strong>not</strong> share audience demographics and interest
            reports with you. <br />
            If you want to use those reports, you may have to update your privacy policy. This is to inform users you
            are sharing data about their browsing behavior with Google.
          </li>
          <li>
            After you finish, you receive your <strong>Google Analytics tracking ID</strong> (e.g.{" "}
            <em>UA-141112345-1</em>). Along with it, you will receive the code snippet to put into the source code of
            all of your pages.
          </li>
        </ul>
        <H as="h2">How to implement Google Analytics?</H>
        <p>There are generally two ways to implement a Google Analytics:</p>

        <ol>
          <li>
            You paste the Google Analytics tracking code into the source code of all pages. You can do this page by
            page or you paste it into a common template file. That could be the header, navigation or footer that all
            pages have in common.
            <br />
            <strong>I don’t recommend</strong> this way of implementing Google Analytics, unless you want to do it
            quick and easy and never touch the analytics setup again. <br />
            Most tracking setups are done faster and easier with a <Link to="/en/tag-management">tag management system</Link>. It is an easier and also
            more flexible process, so it is worth to introduce yet <em>another tool</em> into the stack.
          </li>

          <li>
            You use a tag management system, like Google Tag Manager, which is pretty popular.
            <br />
            There are other alternatives like <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> or{" "}
            <em>Matomo</em> (open source), too. <br />
            Generally, they all serve the same purpose and are very similar. You can implement Google Analytics with a
            non-Google tag management system without worry. Google Tag Manager just happens to be the one that
            everybody can easily get access to and has the most resources available online.
            <br />
            If you want to learn more about it, check out my <Link to="/en/google-tag-manager-setup">Google Tag Manager tutorial</Link>.
          </li>
        </ol>

        <H as="h2">How to implement Google Analytics with Google Tag Manager?</H>
        <p>
          As for the <strong>implementation of Google Analytics via Google Tag Manager,</strong> follow these steps:
        </p>

        <ul>
          <li>
            Visit{" "}
            <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
              tagmanager.google.com
            </a>{" "}
            and create an account.{" "}
          </li>{" "}
        </ul>
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-create-account.png"
          alt="Google Tag Manager create account"
          className="article-img"
        />

        <ul>
          <li>
            With the account you will create a container for your website, so make sure to select <strong>Web</strong>{" "}
            for its usage:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
          alt="Google Tag Manager web container creation screenshot"
          className="article-img"
        />

        <p>
          afterwards, you will receive the <strong>Google Tag Manager code snippet</strong> for implementation:
        </p>

        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager code snippet"
          className="article-img"
        />

        <p>
          Add the first part to the <code>&lt;head&gt; </code>
          tag on all pages of your website. The other part is added inside the <code>&lt;body&gt;</code> tag.
        </p>
        <p>After the Google Tag Manager (GTM) snippet is added, the analytics setup can be configured in a breeze!</p>

        <ul>
          <li>
            In GTM, click on <strong>tags</strong> in the left navigation and <strong>add a new tag</strong>. An
            overlay will slide in from the side and you can give the tag a <strong>name</strong>, e.g.{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Then click on the tag configuration box:</li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-create-new-tag.png"
            alt="google tag manager create new tag"
            className="article-img"
          />

          <li>
            A list with tag types will slide into the screen. <br />
            Select the <strong>Google Analytics - Universal Analytics</strong> tag and start the configuration.
          </li>

          <ImgContainerFixed width="462px">
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
              alt="google tag manager create Google Analytics tag"
              className="article-img"
            />
          </ImgContainerFixed>

          <li>
            Keep the <strong>Track Type</strong> to be <strong>Pageview</strong>. <br />
          </li>
          <li>
            Then <strong>create a new Google Analytics settings variable.</strong>
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
            alt="Google Tag Manager settings variable for Google Analytics screenshot"
            className="article-img"
          />

          <li>
            To configure the settings variable, you first give it a <strong>name</strong>. Right after, paste in the{" "}
            <strong>Google Analytics Tracking ID</strong> (<em>UA-141112345-1</em>), that you{" "}
            <Link to="#where-to-get-the-google-analytics-tracking-code">received earlier</Link>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
            alt="Google Tag Manager Google Analytics setup tracking id screenshot"
            className="article-img"
          />

          <li>
            Click <strong>save</strong>.
          </li>
          <li>
            Back in the tag configuration, click the <strong>triggering</strong> section to choose a trigger.
          </li>

          <li>
            Select <strong>all pages</strong>, because we want the Google Analytics code snippet to run on all pages.
          </li>

          <li>
            <strong>Save</strong> the trigger as well as the tag configuration and get back to the Google Tag Manager
            overview.
          </li>

          <li>
            Hit the blue <strong>submit</strong> button in the top right corner. Give your first container version a{" "}
            <strong>name</strong> and click on <strong>publish</strong>. <br />
            Now you have successfully implemented Google Analytics with Google Tag Manager!
          </li>
        </ul>

        <p>
          You should see data coming into your Google Analytics property after about an hour or so.
          <br />
          You can also have a look at the real-time report to see if there is already some data coming in from your
          current visit.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
          alt="Google Analytics realtime report overview"
        />

        <p>
          Notice you could implement other tools or scripts and trigger them on <strong>all pages</strong>, in just a
          few minutes? <br /> I hope this makes you realize the ease and power of a tag management system.
        </p>

        <H as="h2">How to set up goals in Google Analytics?</H>
        <p>
          Now that you are receiving visitor data, it is time to set up <strong>Google Analytics goals</strong>.
        </p>
        <p>Chances are, you are selling something on your website or there is a form that people can fill out. </p>
        <p>
          Normally, after an order, users land on a <strong>thank you page</strong>. It usually says thank you for the
          order and lists up some order details. <br /> Look into the address bar and see what URL that page has.{" "}
        </p>
        <p>For example, it could look like this:</p>
        <p>
          <code>https://www.yoursite.com/thank-you.php</code>{" "}
        </p>
        <p>
          Let’s set up a goal for this address and count how often this page is visited to indicate how many orders
          took place.
        </p>

        <ul>
          <li>
            Go to the Google Analytics <strong>admin area</strong> with an overview of your account, property and
            view. Click <strong>goals</strong> within the <strong>view</strong> section.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-admin-area-goals.png"
          alt="Google Analytics admin area goals"
          className="article-img"
        />

        <ul>
          <li>
            <strong>Add</strong> a new goal and select <strong>custom</strong> in the goal setup. Then{" "}
            <strong>continue</strong>.
          </li>
          <li>
            Give your goal a <strong>name</strong>, for example <em>Newsletter subscription</em> or{" "}
            <em>Order form received</em> and select the type to be a <strong>destination</strong>. Then{" "}
            <strong>continue</strong>.
          </li>
          <li>
            Under <strong>destination</strong> is <strong>equal to</strong>, add the URL <strong>path</strong>, so in
            our example:
            <p>
              <code>/thank-you.php</code>
            </p>
            You can also get nifty and select <strong>Regular Expression</strong> as the match type in the dropdown
            menu.
            <br />
            If you don’t know what <strong>regular expressions</strong> are: They are a powerful and handy way of
            matching strings. Here you can learn how to use them in an hour or two:{" "}
            <a href="https://regexone.com/" target="_blank" rel="noopener">
              RegexOne
            </a>
          </li>
          <li>
            You can optionally assign a <strong>value</strong> to your goal. I would recommend it, if you can assign a
            numeric value to each of those thank-you pageviews.
            <br /> There are multiple ways to do this. The easiest is, to look at the monthly revenue from those
            thank-you pages and divide it by the total number of pageviews.
            <br />
            That will give you the average for each time the goal hit.
          </li>
          <li>
            <strong>Set up a funnel</strong> if the user journey is supposed to hit some specific pages before the
            conversion. This makes a lot of sense for forms that are split up across multiple steps. You can check
            later if a proportion of visitors falls out of the funnel somewhere during the process. <br />
            You can add steps to the funnel by giving each step a <strong>name</strong> and a{" "}
            <strong>page path</strong> (<em>see below</em>). <br />
            Note that, if you chose <strong>equal to</strong> in the upper destination dropdown, you have to use the
            same match type for your funnel rules.
            <br />
            Or if you chose <strong>regular expressions</strong>, you have to follow regex syntax in your page
            matching rules.
          </li>
          <li>
            Before saving, click on <strong>verify goal</strong>. <br />
            If you already have some data in your GA property, it will run through it and check for matches in the
            historical data. <br />
            If you have zero matches, but know there must be some, you likely made a mistake specifying the page
            paths.
            <br /> So, this is a good place to double-check the rules are working, before you finish the goal setup.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-goals.png"
          alt="Google Analytics setup for goals screenshot"
          className="article-img"
        />

        <p>
          Congratulations! You just set up your goal and can track goal conversions going forward! <br />
          Goals should be set up for the most important conversions of your website. If other conversions are less
          critical or maybe they are not represented with their own page path, you can set up a Google Analytics
          events.
          <br />
          After setting up an event you can use it to base your goal conversions on it.
        </p>

        <H as="h2">Set up events in Google Analytics</H>
        <p>
          The process of <Link to="/en/event-tracking">setting up events in Google Analytics</Link> can look very
          different for each event. Depending on the interaction and website's technology, there are different steps
          to take. Below you can find a list of different event tracking examples.
        </p>
        <H as="h3">Examples for event tracking</H>

        <ul>
          <li>sharing your content on social media</li>
          <li>playing the video on your front page</li>
          <li>hitting the back button to the Google Search Results</li>
          <li>scrolling down to the bottom of your article</li>
          <li>clicking on your contact email</li>
          <li>adding a product to the shopping basket</li>
          <li>selecting a color variation of a product</li>
        </ul>

        <p>
          The procedure for setting up scroll tracking is different than for tracking mouse clicks. On dynamic
          websites, HTML elements are not always visible. Hence, click tracking would require a different approach,
          than on static websites. <br />
          The point is, there are many influencing factors, so I wrote a separate event tracking article. There I go
          into detail, step by step, and explain how to track button clicks or other elements. Have a look at it here:
        </p>
        <p>
          <Link to="/en/event-tracking">Event tracking in Google Analytics</Link>
        </p>
        <H as="h2">Set up campaign tracking in Google Analytics</H>
        <p>
          Campaign tracking is already built into every Google Analytics Setup. You just have to add <Link to="/en/wiki-analytics/utm-parameter">UTM parameters</Link> to
          the links in your email or social media campaigns.
        </p>
        <p>
          There are five parameters that you can add to any link pointing to your website. The built-in Google
          Analytics campaign tracking recognizes a user from such a link. It then attributes the visit to the
          specified campaign and you can find it in the campaign report.
        </p>
        <p>
          Let's take the URL <code>https://www.yoursite.com/campaign</code>
          <br />
          and the following campaign tracking parameters as an example:
        </p>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>UTM Parameter</th>
              <th>Parameter value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Source</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Guest post</td>
            </tr>
            <tr>
              <td>UTM Campaign</td>
              <td>Spring sales</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Boots</td>
            </tr>
            <tr>
              <td>UTM Content</td>
              <td>Boots when it’s warm outside</td>
            </tr>
          </tbody>
        </table>

        <p>
          This is the <strong>same URL including UTM parameters</strong>:
        </p>
        <p>
          <code>
            https://www.yoursite.com/campaign?<strong>UTM_source</strong>
            =medium&amp;<strong>UTM_medium</strong>
            =guest-post&amp;<strong>UTM_campaign</strong>=spring-sales&amp;
            <strong>UTM_term</strong>=boots&amp;<strong>UTM_content</strong>
            =boots-when-its-warm-outside
          </code>
        </p>
        <p>
          You can use it on social media or on other sources that link to your site. Google Analytics tracks
          automatically how many clicks reach you from such a link.
        </p>
        <p>
          Notice the <strong>five UTM parameters</strong> in the URL? You don't have to add those manually. There are{" "}
          <a
            href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
            target="_blank"
            title="Tool to build links with UTM parameters"
          >
            UTM Builder tools
          </a>{" "}
          that do that for you. You just give them the <strong>link to your website</strong> and values for the{" "}
          <strong>UTM parameters</strong>. Afterwards, they'll give you the campaign tracking link, including all UTM
          parameters, back.
        </p>
        {/* <p>
              I wrote a separate article on{" "}
              <Link href="/en/campaign-tracking">
                UTM campaign tracking
              </Link>
              , where I
              explain the different UTM parameters, how to find them in Google
              Analytics and how to establish a workflow to handle campaign
              tracking on a regular basis. <br />
              You can read it right here:
            </p>

            <p>
              <Link to="/en/campaign-tracking">
                Campaign tracking in Google Analytics
              </Link>
            </p>
            <H as="h2">Google Analytics Best Practices</H> */}


        <H as="h2">Google Analytics Best Practices</H>
        <p>
          Below is a list of additional <strong>best practices</strong> for setting up Google Analytics. I apply each one vigorously
          for each GA setup I configure. They are not just <em>nice to have</em>, but rather necessary to maximize
          data quality. <br />
          Filters can be set up in the tag management system too. In fact, I prefer it, because JavaScript allows for
          more advanced rules when filtering. Nevertheless, filters in Google Analytics are simpler and get the job
          done almost as well.
        </p>
        <ol start="1">
          <li>
            <H as="h3">Exclude own traffic from Google Analytics</H>
            Now that you are tracking visits, exclude your own sessions from your website traffic. Your browsing
            behavior will differ from regular users and otherwise skew your data. This counts especially for low
            traffic pages, since own pageviews weigh in heavier overall.
            <br />
            To <strong>exclude your own traffic</strong> you can filter yourself out by <strong>IP address</strong>,
            if you have a static IP.
            <br />
            If not, you may want to consider to get one from your internet provider, since they don't cost much
            usually.
          </li>
        </ol>
        <ul>
          <li>
            To <strong>set up IP exclusion in Google Analytics</strong>, go to the <strong>Admin panel</strong> and
            click on <strong>Filters</strong> within the <strong>view</strong> column on the right.
          </li>
          <li>
            Add a <strong>name</strong> for your filter and select the <strong>filter type</strong> to be{" "}
            <strong>custom</strong>.
          </li>
          <li>
            Choose the <strong>exclude</strong> filter option and select <strong>IP Address</strong> in the dropdown.
          </li>
          <li>
            Then enter your static IP into the <strong>filter pattern</strong> field and click <strong>save</strong>.
          </li>
        </ul>

        <ImgContainerFixed width="415px">
          <ImgScreenshot
            src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
            alt="Exclude IP from Google Analytics setup screenshot"
            className="article-img"
          />
        </ImgContainerFixed>

        <ol start="2">
          <li>
            <H as="h3">Transform page paths to lowercase</H>
            Another tweak is to automatically <strong>transform your page path to lowercase</strong>.
            <br />
            Humans make mistakes and at some stage, somebody will create a URL with capital letters in its name. If
            the website then returns the same content, as for the same URL with lower case letters, things get messy.{" "}
            <br /> Let me explain. <br />
            We are essentially sending data under two different page paths, though the data is for the same page.
            <br />
            Page paths written in varying cases lead to messy analysis in Google Analytics. Any unique writing of the
            same path will be shown on its own row in the reports. After a while, you end up looking at multiple rows
            and their metrics, even though they should be combined. <br />
            If you then also earn backlinks to the same page, but the address in the link was written in a funny way,
            the mess is perfect. So let's try to keep our life drama-free and fix this beforehand.
          </li>
        </ol>
        <ul>
          <li>
            To <strong>transform URL paths to lowercase</strong> visit the <strong>Filters</strong> for your view
            again and add a new filter. Give it a name and select the <strong>custom</strong> filter type.
          </li>
          <li>
            Now choose <strong>Lowercase</strong> and select <strong>Request URI</strong> in the field dropdown.
          </li>
        </ul>

        <ImgContainerFixed width="369px">
          <ImgScreenshot
            src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
            alt="Set up lowercase URLs in Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />

        <ul>
          <li>
            Click <strong>save</strong> to finish. Easy fix, that saves many headaches.
          </li>
        </ul>
        <ol start="3">
          <li>
            <H as="h3">Google Analytics View Structure</H>
            <p>
              A <strong>view</strong> is where you access all your Google Analytics reports. If there are filters set
              up for your view, all reports will be filtered accordingly.
              <br />
              Your <strong>view structure</strong> should usually mimic your organization’s structure. If your target
              markets are e.g. rather regional than country-based, set up your views the same way. It will come in
              handy when KPIs for regions, are directly accessible.
              <br />
              Another orientation is your organization’s structure for budgeting and marketing efforts. If it is done
              on a country level, it’s probably a good idea to follow that structure for the views too.
              <br />
              You can create up to 25 views per property. So, depending on the number of markets and marketing
              channels, several view structures are possible.
            </p>
            <H as="h3">Example: Google Analytics view structure for local websites</H>
            <p>
              1.0 Master
              <br />
              2.0 Internal traffic
              <br />
              3.0 Raw data
            </p>
            <p>
              One way or another, there are usually 3 types of views. They could be filtered down further by country
              or device, if it makes sense for your organization.
            </p>
            <H as="h3">Example: Google Analytics view structure for multinational websites</H>
            <p>
              1.1 Master (US)
              <br />
              1.2 Master (UK)
              <br />
              1.3 Master (DE)
              <br />
              1.4 Master (FR)
              <br />
              1.5 Master (ES)
              <br />
              1.6 Master (MX)
              <br />
              1.7 Master (RUS)
              <br />
              2.0 Internal Traffic
              <br />
              3.0 Raw Data
            </p>

            <H as="h3">Example: Google Analytics regional view structure</H>
            <p>
              1.0 Master (NA)
              <br />
              1.1 Master (EMEA)
              <br />
              1.2 Master (LATAM)
              <br />
              1.3 Master (APEC)
              <br />
              2.0 Internal Traffic
              <br />
              3.0 Raw Data
            </p>

            <p>
              The <strong>Master view</strong> is the primary view for analysis and excludes all internal traffic.
            </p>
            <p>
              The <strong>Internal traffic view</strong> includes all internal traffic. This one can be used for
              debugging and testing purposes too. If you set the include filter, anything else will be excluded
              automatically.
            </p><p>
              In the beginning, Google Analytics creates a first <strong>All Web Site Data view</strong>, together
              with the GA property.
            </p><p>
              Keep it, but change the name to <strong>Raw Data</strong>. You want to have a raw data view without any
              filters. You cannot undo a filter retroactively and look at the unfiltered data. Therefore, it is a best
              practice to keep one view untouched. To change the name, go to{" "}
              <strong>Admin &gt; View &gt; View Settings</strong>.
            </p>
            <p>
              In most cases, companies are focusing their efforts on one or a few countries. In that case, create
              views on a per-country basis and set filters accordingly.
              <br />
              You can also create views per traffic source (organic, paid, direct, social, etc.) or device (desktop,
              tablet, mobile). I use Google Analytics segments for that, but there is no reason to not filter the
              specifics already in the view. I like to keep the number of views low though because it keeps the GA
              user interface clean.
              <br />I may get a bit messier with segments in the view then and apply remaining specifics there.
            </p>
            <p>
              Create all views as early as possible, because they only show data from the moment they were created. A
              new view will not show any historical data from before the creation date. If you need to look back in
              history with specific filters, you fall back on the raw data view. You just apply the intended filters
              with segments instead. This way you get your insight, but don’t alter the data.
            </p>
            <p>
              <strong>TIP</strong>: If some views share the same filters, you can create one and then duplicate it
              along with all filters. Afterwards, you just add any view-specific filters to the duplicates.
              <br />
              Make sure to keep the original view unchanged though, so you only add filters to the duplicates. Messing
              with the original view later on, will affect the duplicated views too.
            </p>
          </li>
          <li>
            <H as="h3">Google Analytics Alerts</H>
            Another best practice is to <strong>set up Google Analytics alerts</strong>. I set it up to get notified
            for decreases in sessions or when the server response time goes up. This way I can look into the reasons
            and potentially do something.
          </li>
        </ol>
        <ul>
          <li>
            To set up an alert in Google Analytics, visit <strong>Customization</strong> in the side menu.
          </li>
          <li>
            Alternatively, you can also visit the <strong>Admin</strong> area again and click on{" "}
            <strong>Custom Alerts</strong> in the view panel.
          </li>
          <li>
            click <strong>Custom Alerts</strong> and click <strong>manage customized alerts</strong>
          </li>
          <li>
            Configuring a Google Analytics alert for decreasing sessions is pretty simple. Click{" "}
            <strong>New Alert</strong> and name the alert for example <strong>Session decrease</strong> with the{" "}
            <strong>Period</strong> set to <strong>Week</strong>.
          </li>
          <li>Also, check the box to receive an email when the alert triggers.</li>{" "}
          <li>
            There are usually traffic fluctuations between weekdays, weekends and national holidays. We don’t want to
            be alerted for those fluctuations, so we set it up to look at weekly session data.{" "}
          </li>
          <li>
            The <strong>Alert Conditions</strong> apply to <strong>all traffic</strong> and you want to be alerted
            when <strong>sessions decrease by more than 35% compared to the previous week.</strong>
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
          alt="set up Google Analytics alerts for session decrease screenshot"
          className="article-img"
        />

        <p>
          Afterwards, hit <strong>save</strong> and let’s add the next alert.
        </p>
        <p>To set up a Google Analytics alert for an increasing server response time,</p>
        <ul>
          <li>
            click on <strong>New Alert</strong> and give it a name, like{" "}
            <em>Avg. server response time increase day to day</em> and
          </li>
          <li>
            select <strong>day</strong> as the period.
          </li>
          <li>
            For the <strong>alert conditions</strong>, it applies to <strong>all traffic</strong> and you want to be
            alerted when your <strong>avg. server response time increases by more than 300%</strong> compared to the{" "}
            <strong>previous day</strong>:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
          alt="set up Google Analytics alerts for server response time screenshot"
          className="article-img"
        />

        <p>
          Afterwards, hit <strong>save</strong>.<br />
          Now you can be sure to receive an alert email when your traffic drops down or when your server is having
          problems.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsSetup;
